import React from 'react'
import RequestDemoForm from 'Components/requestDemo/RequestDemoForm'
import Divider from 'Components/common/Divider'
import FAQ from 'Components/contact/FAQ'
import Enticement from 'Components/home/Enticement'
import ProductShowcase from 'Components/contact/ProductShowcase'
import Clients from 'Components/common/Clients'
import { graphql } from 'gatsby'

export interface ContactType {
  [key: string]: {
    [key2: string]: string
  }
}

interface IProps {
  location: any
  data: {
    stringsJson: {
      contact: {
        message: {
          [key: string]: string
        }
        faq: ContactType
        enticement: {
          header: string
          message: string
        }
        clients: {
          header: string
          languages: {
            [key2: string]: string
          }
          more: string
        }
      }
      requestDemo: {
        message: {
          [key: string]: string
        }
      }
    }
  }
}

const RequestDemoPage: React.FC<IProps> = ({
  data: {
    stringsJson: { requestDemo, contact },
  },
  location,
}) => (
  <>
    <RequestDemoForm location={location} message={requestDemo.message} />
    <Divider />
    <FAQ faq={contact.faq} />
    <Divider />
    <Enticement
      headerId={contact.enticement.header}
      messageId={contact.enticement.message}
      noIntl={true}
    />
    <ProductShowcase />
    <Clients headerId={contact.clients.header}
             usaId={contact.clients.languages.usa}
             ukId={contact.clients.languages.uk}
             noId={contact.clients.languages.no}
             moreId={contact.clients.more} />
  </>
)

export default RequestDemoPage

export const query = graphql`
  query NoRequestDemoQuery {
    stringsJson(lang: { eq: "no" }) {
      ...ContactAndRequestDemo
    }
  }
`
