import React, { Component } from 'react'
import BgImage from 'Components/common/BgImage'
import { graphql, StaticQuery } from 'gatsby'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button, Col, Container, Form, Row } from 'reactstrap'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird, faCheck } from '@fortawesome/pro-regular-svg-icons'
import Parser from 'html-react-parser'
import CustomFormGroup from 'Common/CustomFormGroup'
import Helmet from 'react-helmet'

interface State {
  apiUrl: string
  firstName: string
  lastName: string
  companyName: string
  phoneNumber: string
  email: string
  buttonState: string
}

class RequestDemoForm extends Component<any, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      apiUrl: process.env.GATSBY_BASE_URL || '',
      firstName: '',
      lastName: '',
      companyName: '',
      phoneNumber: '',
      email: '',
      buttonState: props.message.buttonIdle,
    }
  }

  currentCulture = () => {
    const pathnameArray: string[] = this.props.location.pathname.split('/')
    switch (pathnameArray[1]) {
      case 'bestill-en-demo':
        return 'nb-NO'
      case 'en':
        return 'en-US'
      case 'pl':
        return 'pl-PL'
      default:
        return 'en-US'
    }
  }

  render() {
    const buttonState: any = {
      IDLE: this.props.message.buttonIdle,
      SENDING: this.props.message.buttonSending,
      SUCCESS: this.props.message.buttonSuccess,
      ERROR: this.props.message.buttonError,
    }

    const { message } = this.props
    return (
      <StaticQuery
        query={graphql`
          query requestDemoBackgroundImageQuery {
            bgImage: file(
              relativePath: { eq: "contact/contact-intro-bg@2x.png" }
            ) {
              childImageSharp {
                fixed(width: 2560, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet>
              <script type="text/javascript" async>
                {`
                      const demoSubmitButtonId = 'demoSubmitButton';
                     
                       function trackingListener() {
                       const capterra_vkey = \`${process.env.GATSBY_CAPTERRA_VKEY}\`,
                       capterra_vid = \`${process.env.GATSBY_CAPTERRA_VID}\`,
                       capterra_prefix = (('https:' == document.location.protocol)
                         ? 'https://ct.capterra.com' : 'http://ct.capterra.com');
                       const ct = document.createElement('script');
                       ct.type = 'text/javascript';
                       ct.async = true;
                       ct.src = capterra_prefix + '/capterra_tracker.js?vid='
                         + capterra_vid + '&vkey=' + capterra_vkey;
                       const s = document.getElementsByTagName('script')[0];
                       s.parentNode.insertBefore(ct, s);
                     };
                     const demoButton = document.getElementById(demoSubmitButtonId);
     
                     demoButton.addEventListener(
                       'click',
                       trackingListener
                     );  
                    `}
              </script>
            </Helmet>
          <section>
            <BgImage imgFixed={data.bgImage.childImageSharp.fixed}>
              <div className="container py-6">
                <h1 className="display-4 mb-4 text-center">
                  {Parser(message.header)}
                </h1>
                <h1 className="mb-4 text-center">{message.subheader}</h1>
                <h3 className="text-center">
                  <FontAwesomeIcon
                    className="mx-2"
                    icon={faCheck}
                    color="green"
                  />
                  {message.p1}
                </h3>
                <h3 className="text-center">
                  <FontAwesomeIcon
                    className="mx-2"
                    icon={faCheck}
                    color="green"
                  />
                  {message.p2}
                </h3>
                <h3 style={{ marginBottom: 170 }} className="text-center pb-4">
                  <FontAwesomeIcon
                    className="mx-2"
                    icon={faCheck}
                    color="green"
                  />
                  {message.p3}
                </h3>
              </div>
            </BgImage>
            <Container className="message-container">
              <Row>
                <Col xs={0} md={2} lg={3} />
                <Col xs={12} md={8} lg={6}>
                  <Form
                    onChange={({ target: { name, value } }) => {
                      this.setState({ [name]: value })
                    }}
                    onSubmit={e => {
                      e.preventDefault()
                      this.setState({ buttonState: buttonState.SENDING })
                      this.props.googleReCaptchaProps
                        .executeRecaptcha()
                        .then((token: string) => {
                          const model = {
                            reCaptcha: token,
                            firstName: this.state.firstName,
                            lastName: this.state.lastName,
                            companyName: this.state.companyName,
                            phoneNumber: this.state.phoneNumber,
                            email: this.state.email,
                            culture: this.currentCulture(),
                          }

                          axios
                            .post(`${this.state.apiUrl}/api/requestdemo`, model)
                            .then(() => {
                              this.setState({
                                buttonState: buttonState.SUCCESS,
                                firstName: '',
                                lastName: '',
                                companyName: '',
                                phoneNumber: '',
                                email: '',
                              })
                            })
                            .catch(() =>
                              this.setState({
                                buttonState: buttonState.ERROR,
                              })
                            )
                        })
                    }}
                  >
                    <CustomFormGroup
                      label={message.firstName}
                      value={this.state.firstName}
                      require
                      name="firstName"
                    />
                    <CustomFormGroup
                      label={message.lastName}
                      value={this.state.lastName}
                      name="lastName"
                    />
                    <CustomFormGroup
                      label={message.companyName}
                      value={this.state.companyName}
                      name="companyName"
                    />
                    <CustomFormGroup
                      label={message.phoneNumber}
                      value={this.state.phoneNumber}
                      type="number"
                      name="phoneNumber"
                    />
                    <CustomFormGroup
                      label={message.emailLabel}
                      value={this.state.email}
                      placeholder={message.emailPlaceholder}
                      require
                      type="email"
                      name="email"
                    />
                    <div className="message-button-wrapper">
                      <div>
                        <Button
                          type="submit"
                          color="primary"
                          className="message-button"
                          id="demoSubmitButton"
                        >
                          <FormattedMessage id={this.state.buttonState} />
                          {this.state.buttonState === buttonState.SENDING && (
                            <FontAwesomeIcon
                              className="mx-2"
                              icon={faSpinnerThird}
                              spin
                            />
                          )}
                        </Button>
                        <p className="message-recaptcha-disclaimer">
                          This website is protected by reCAPTCHA.
                          <br />
                          Google{' '}
                          <a
                            href="https://policies.google.com/privacy"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>{' '}
                          and{' '}
                          <a
                            href="https://policies.google.com/terms"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Terms of Service
                          </a>{' '}
                          apply.
                        </p>
                      </div>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Container>
          </section>
            </>
        )}
      />
    )
  }
}

export default withGoogleReCaptcha(injectIntl(RequestDemoForm))
